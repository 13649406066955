import { createApp } from 'vue'
import App from './App.vue'
// import VueAMap, {
//     initAMapApiLoader,
// } from '@vuemap/vue-amap';
// import VueAMapLoca from '@vuemap/vue-amap-loca';
import { Notify, Dialog } from 'vant';
import 'vant/lib/index.css'
// initAMapApiLoader({
//     key: '',
//     // securityJsCode: '562031eec944e6937548c6ad4a20a775', // 新版key需要配合安全密钥使用
//     // Loca:{
//     //  version: '2.0.0'
//     // } // 如果需要使用loca组件库，需要加载Loca
//     AMapUI: { version: '1.1', plugins: ['geo/DistrictExplorer'] },
// });


const app = createApp(App)
// app.use(VueAMap)
// app.use(VueAMapLoca)
app.use(Notify)
app.use(Dialog)
app.mount('#app')
